.navbar{
  padding-top: 0.5rem ;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  
  a{
    color:white;
    padding: 1rem;
  }
}

@media only screen and (max-width: $breakpoint-mobile) { // screens < 600px
  .navbar {
    font-size: 1.4rem;
    a{
      padding: 1rem 0;
    }
  }
}