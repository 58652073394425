/* ------------------------ Typography ------------------------ */

$base-font-default: 'Roboto', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif !default;
$base-font-mono: 'Roboto Mono', monospace !default;

// Only font-weights used
// 300 default
// 700 bold


body{	
	font-weight: 300;
	font-family: $base-font-default;

    color: $base-color-typography-text;
    background-color: $base-color-typography-background;
}

b,
strong{
	font-weight: 700;
}

p{
	margin-top: 0;
}
.muted{
    color:$base-color-typography-text-muted;
}

h1,
h2,
h3,
h4,
h5,
h6{
	font-weight: 300;
	letter-spacing: -.1rem;
	margin-bottom: 2.0rem;
	margin-top: 0;
}

h1{
	font-size: 4.6rem;
	line-height: 1.2;
}

h2{
	font-size: 3.6rem;
	line-height: 1.25;
}

h3{
	font-size: 2.8rem;
	line-height: 1.3;
}

h4{
	font-size: 2.2rem;
	letter-spacing: -.08rem;
	line-height: 1.35;
}

h5{
	font-size: 1.8rem;
	letter-spacing: -.05rem;
	line-height: 1.5;
}

h6{
	font-size: 1.6rem;
	letter-spacing: 0;
	line-height: 1.4;
}