// ------------------------------------------------------------------------
// Landing
// ------------------------------------------------------------------------

#landing {
  padding-top: 6rem;
  padding-bottom: 6rem;
  text-align: center;
  h1 {
    //   font-size: 3rem;
    font-size: 3.3rem;
    margin-bottom: 3rem;
  }
  p {
    font-size: 1.72rem;
  }
  a {
    border: 1px solid #ffffff3f;
  }
}

// ------------------------------------------------------------------------
// Process
// ------------------------------------------------------------------------

#process {
  .card {
    padding: 2rem;
    img {
      margin-bottom: 2rem;
    }
  }
}

@media only screen and (max-width: $breakpoint-mobile) {
  // screens < 600px
  #process {
    .card {
      margin-bottom: 3rem;
    }
  }
}

// ------------------------------------------------------------------------
// Industries
// ------------------------------------------------------------------------

#industries {
  .industry {
    margin-bottom: 3rem;
  }
  img {
    max-width: 15rem;
  }
  h3 {
    font-size: 2rem;
    color: $color-grey-muted;
  }
}

// ------------------------------------------------------------------------
// Team
// ------------------------------------------------------------------------

#team {
  text-align: center;
  img {
    border-radius: 50%;
    max-width: 120px;
  }
  h3 {
    margin-bottom: 0;
  }
}

// ------------------------------------------------------------------------
// Contact
// ------------------------------------------------------------------------

#contact {
  form {
    display: flex;
    flex-direction: column;
  }

  a {
    color: white;
  }

  input,
  textarea {
    border: 1px solid #ffffff3f;
    background: $color-primary;
    color: $color-grey-light;
    font-size: 1.4rem;
  }

  .validation-text {
    position: absolute;
    font-size: 1.4rem;
  }
  .validation-fail {
    border: 0.1rem solid $color-danger;
  }
}

// ------------------------------------------------------------------------
// careers
// ------------------------------------------------------------------------

#careers {
  padding-top: 1rem;
  padding-bottom: 2rem;
  text-align: center;
  h1 {
    text-align: center;
    font-size: 4rem;
  }
}
