/* ------------------------ Sizing ------------------------ */

// Breakpoints
$base-sizing-breakpoint-mobile: 40.0rem !default; //400px
$base-sizing-breakpoint-tablet: 80.0rem !default;  //800px
$base-sizing-breakpoint-desktop: 120.0rem !default; //1200px

// Standard full-width
$base-sizing-full-width: 112.0rem !default; //1120px

// Set box-sizing globally to handle padding and border widths
*,
*:after,
*:before {
	box-sizing: inherit;
}

// The base font-size is set at 62.5% for having the convenience
// of sizing rems in a way that is similar to using px: 1.6rem = 16px
html{
	box-sizing: border-box; // includes content, padding and border in width/height
	font-size: 62.5%; // This makes 1rem = 10px, so font-size is 16px or 1.6rem
}
body{
	font-size: 1.6em; // Make sure rem and em match
	letter-spacing: .01em;
	line-height: 1.6;
}