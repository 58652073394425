/* ------------------------ Shadow ------------------------ */

// Use lie this: ;
// @extend %shadow;
// @extend %shadow-primary;

// box-shadow: h-offset v-offset blur spread color;

%shadow{
    -webkit-box-shadow: 0px 5px 10px 0px rgba( $base-color-shadow, .2 );
    -moz-box-shadow: 0px 5px 10px 0px rgba( $base-color-shadow, .2 );
    box-shadow: 0px 5px 10px 0px rgba( $base-color-shadow, .2 );
}

%shadow-primary{
    -webkit-box-shadow: 0px 5px 10px 0px rgba( $base-color-shadow-primary, .2 );
    -moz-box-shadow: 0px 5px 10px 0px rgba( $base-color-shadow-primary, .2 );
    box-shadow: 0px 5px 10px 0px rgba( $base-color-shadow-primary, .2 );
}
