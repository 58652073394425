/* ------------------------ Form ------------------------ */

input[type='email'],
input[type='number'],
input[type='password'],
input[type='search'],
input[type='tel'],
input[type='text'],
input[type='url'],
input[type='color'],
input[type='date'],
input[type='month'],
input[type='week'],
input[type='datetime'],
input[type='datetime-local'],
input:not([type]),
textarea,
select{
	-webkit-appearance: none;
    -moz-appearance: none;
	appearance: none; // Removes awkward default styles on some inputs for iOS
	background-color: $base-color-form-background;
	color: $base-color-form-text;
	border: .1rem solid $base-color-form-border;
	border-radius: .4rem;
	box-shadow: none;
	box-sizing: inherit; // Forced to replace inherit values of the normalize.css
	height: 3.8rem;
	padding: .6rem 1.0rem; // The .6rem vertically centers text on FF, ignored by Webkit
	//width: 100%;
    
	&:focus{
		border-color: $base-color-form-border-focus;
		outline: 0;
	}
}

// Hide number input up/down arrows - Mozila
input[type=number] { 
	-moz-appearance: textfield;
	appearance: textfield;
	margin: 0;
}
// Hide number input up/down arrows - Chrome
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
	-webkit-appearance: none;
	margin: 0;
}

// The the icon svg fill color has to be manually changed
@mixin svg-icon($hex-color){
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 29 14" width="29"><path fill="rgb(#{red($hex-color)}, #{green($hex-color)}, #{blue($hex-color)})" d="M9.37727 3.625l5.08154 6.93523L19.54036 3.625"/></svg>') center right no-repeat;
}

select{
    @include svg-icon($base-color-form-select-icon);
    background-color: $base-color-form-background;
	padding-right: 3.0rem;

    &:focus{
        @include svg-icon($base-color-form-select-icon-focus);
		background-color: $base-color-form-background;
	}
}

textarea{
	min-height: 6.5rem;
}

label,
legend{
	display: block;
	font-size: 1.6rem;
	font-weight: 700;
	margin-bottom: .5rem;
}

fieldset{
	border-width: 0;
	padding: 0;
}

input[type='checkbox'],
input[type='radio']{
    display: inline;
    cursor: pointer;    
}

.label-inline{
	display: inline-block;
	font-weight: normal;
	margin-left: .5rem;
}