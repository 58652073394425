
// Project colors
$color-primary:#1e345c;
// $color-primary:#342a6b;
// $color-primary:#1a2331;
// $color-primary:#508df5; //#507df5
$color-primary-accent:#6c63ff;
$color-grey-dark:#4b5766;
$color-grey:#737f8f;
$color-grey-muted:#bac1c9;
$color-grey-light:#f7f7f7;

$color-danger:#e05616;

$base-color-global-primary: $color-primary;
$base-color-global-primary-accent:  $color-primary-accent;

 $base-color-typography-text: $color-grey-dark;
$base-color-typography-background:$color-grey-light;
// $base-color-typography-text-muted: $base-color-global-grey !default;






